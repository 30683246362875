import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { of, switchMap } from 'rxjs';
import { StoryblokService } from '../services/storyblok.service';

export const storyblokPricingBoxContentResolver: ResolveFn<unknown> = route => {
  const storyblokService = inject(StoryblokService);
  return inject(StoryblokService)
    .getUniqueComponentsList(route)
    .pipe(
      switchMap(components => {
        if (components.has('PricingBoxList')) {
          return storyblokService.getStories({ content_type: 'PricingBox' }, route);
        }
        return of(undefined);
      })
    );
};
