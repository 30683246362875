import { isPlatformServer } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ISbRichtext, renderRichText } from '@storyblok/js';
import { CookieService } from 'ngx-cookie-service';
import { lastValueFrom } from 'rxjs';
import { StoryblokLink } from '../storyblok/storyblok-types';
import { BrowserService } from './browser.service';
import { PromotionService } from './promotion.service';
import { RedirectService } from './redirect.service';
import { StorageService } from './storage.service';
import { StoryblokService } from './storyblok.service';
import { ToastService } from './toast.service';

interface Affiliate {
  slug: string;
  name: string;
  content: {
    image: {
      filename: string;
    };
    source: string;
    medium: string;
    campaign: string;
    content: ISbRichtext;
    ctaText: string;
    ctaLink: StoryblokLink;
    component: 'Affiliate';
    promoCode?: string;
    backgroundImage?: {
      filename?: string;
    };
  };
}

@Injectable({
  providedIn: 'root',
})
export class AffiliateService {
  private storyblokService = inject(StoryblokService);
  private route = inject(ActivatedRoute);
  private promotionService = inject(PromotionService);
  private storage = inject(StorageService);
  private toasts = inject(ToastService);
  private cookieService = inject(CookieService);
  private redirectService = inject(RedirectService);
  private browserService = inject(BrowserService);
  private platformId = inject<string>(PLATFORM_ID);

  public async getAffiliate(ref: string): Promise<Affiliate> {
    if (!ref) return;
    const resp = await lastValueFrom(
      this.storyblokService.getStory(`technical/affiliate-entities/${ref}`, this.route.snapshot)
    );
    if (!resp) return;
    return resp.story as unknown as Affiliate;
  }

  public async handleAffiliate(affiliate: Affiliate) {
    if (isPlatformServer(this.platformId)) return;
    const promoCode = affiliate.content.promoCode;

    if (promoCode) {
      const promo = await this.promotionService.checkCode(promoCode);
      if (promo) {
        this.storage.set('promo', promo.code);
      }
    }

    let href = this.storyblokService.getUrlLink(affiliate.content.ctaLink);
    if (href.startsWith('/')) {
      href = this.browserService.getCorrectPicflowUrl() + href;
    }

    const toast = this.toasts.add(renderRichText(affiliate.content.content), 'custom', {
      location: 'bottom-right',
      size: 'tiny',
      title: affiliate.name,
      image: affiliate.content.image?.filename,
      backgroundImage: affiliate.content.backgroundImage?.filename,
      backgroundImageSize: affiliate.content.image?.filename ? 128 : 108,
      closable: true,
      vertical: true,
      cta: affiliate.content.ctaText
        ? {
            text: affiliate.content.ctaText,
            color: 'primary',
            onClick: () => {
              this.toasts.close(toast);
              if (href) location.href = href;
            },
          }
        : undefined,
    });
  }

  public async affiliateRedirect(affiliate: Affiliate) {
    let path = '';
    try {
      const page = await lastValueFrom(this.storyblokService.getStory(`lp/${affiliate.slug}`, this.route.snapshot));
      if (page) {
        if (page.story?.full_slug) path = page.story.full_slug;
      }
    } catch {}

    this.cookieService.set('accepted-cookies', 'true', 9627576230894, '/');
    return this.redirectService.redirectTo([path], {
      queryParams: {
        ref: affiliate.slug,
        utm_source: affiliate.content.source,
        utm_campaign: affiliate.content.campaign,
        utm_medium: affiliate.content.medium,
      },
    });
  }
}
