import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { from, of, switchMap } from 'rxjs';
import { StoryblokService } from '../services/storyblok.service';

export const storyblokProductComparisonFeaturesResolver: ResolveFn<unknown> = route => {
  const storyblokService = inject(StoryblokService);

  return storyblokService.getUniqueComponentsList(route).pipe(
    switchMap(components => {
      if (components.has('ProductComparisonList')) {
        return from(storyblokService.getStories({ content_type: 'ProductFeature' }, route));
      }
      return of(undefined);
    })
  );
};
